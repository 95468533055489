@font-face {
  font-family: "Comfortaa";
  src: url(./assets/fonts/Comfortaa-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Comfortaa";
  src: url(./assets/fonts/Comfortaa-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Comfortaa";
  src: url(./assets/fonts/Comfortaa-Light.ttf) format("truetype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Comfortaa-Medium";
  src: url(./assets/fonts/Comfortaa-Medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Comfortaa-SemiBold";
  src: url(./assets/fonts/Comfortaa-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
